<template>
  <main class="translation page_bg_grey blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
    <p class="lang-title weight-700">{{ $t("Sales report") }}</p>
          <div class="header_input_container weight-400">
              <h5 class="weight-700 mb-5">{{ $t("Filter By Distributer") }}</h5>
              <div class="d-flex align-items-center flex-nowrap input_titles" >
                <b-row>
                        <b-col lg="2" class="d-flex align-items-right p-5 space-nowrap">
                        {{ $t("Distributer") }} 
                      </b-col>
                      <b-col lg="2" class="d-flex align-items-right p-5 space-nowrap">
                        {{ $t("Business Name") }} 
                      </b-col>
                      <b-col lg="2" class="d-flex align-items-right p-5 space-nowrap">
                        {{ $t("Client No.") }} 
                      </b-col>
                      <b-col lg="2" class="d-flex align-items-right p-5 space-nowrap">
                        {{ $t("Agent Code") }} 
                      </b-col>
                      <b-col lg="2" class="d-flex align-items-right p-5 space-nowrap">
                        {{ $t("Phone Number") }} 
                      </b-col>
                      <b-col lg="2" class="d-flex align-items-right p-5 space-nowrap">
                        {{ $t("Authorized Dealer") }} 
                      </b-col>
                </b-row>
              </div>

                <div class="d-flex align-items-right flex-nowrap inputs_container">
                <b-row>
                  <b-col>
                    <input  class="input" type="text" v-model="filter.distributor_name"/> 
                  </b-col>
                  <b-col>
                    <input class="input " type="text" v-model="filter.business_name"/> 
                  </b-col>
                  <b-col >
                    <input class="input " type="text" v-model="filter.customer_id" /> 
                  </b-col>
                  <b-col>
                    <input class="input " type="text" v-model="filter.author_id" /> 
                  </b-col>
                  <b-col>
                    <input class="input " type="text" v-model="filter.phone" /> 
                  </b-col>
                  <b-col>
                    <input class="input" type="text" v-model="filter.business_id_filter" /> 
                  </b-col>
                </b-row>
              </div>
      </div>


    <div class="filters-flex-container">
    <div class="filter-part weight-700">
      <h5 class="weight-700 mb-5">{{ $t("Time filtering") }}</h5>
      <div class="d-flex align-items-center flex-wrap">
          <v-row class="mb-5">
            <v-col lg="2" class="d-flex align-items-center p-5 space-nowrap">
              {{ $t("Start Date") }} :
            </v-col>
            <v-col lg="2" class=" d-flex align-items-center p-5">
              <Datepicker 
                v-model="filter.start_date" 
                :format="format"
                class="input tour-person__input cus-datepicker"
                :language="languages[currentLang]"
              ></Datepicker>
              <img src="/assets/img/calander.png" alt="" class="calendar-img">
            </v-col>
            <v-col lg="1">
            </v-col>
            <v-col lg="1" class="d-flex align-items-center p-5 space-nowrap">
              {{ $t("Start hour") }} :
            </v-col>
            <v-col lg="2" class="d-flex align-items-center">
              <vue-select 
                :options="filter.start_hours.options" 
                class="input cus-select time-select mx-2"
                v-model="filter.start_hours.selected"
              >
              </vue-select> 
              <span>:</span> 
              <vue-select 
                :options="filter.start_mins.options" 
                class="input cus-select time-select mx-2"
                v-model="filter.start_mins.selected"
              >
              </vue-select>
            </v-col>

            <v-col lg="3" style="margin: 0 0 5px 20px">
              <div class="range-part">
                <div class="flex-wrap">
                  <label class="">{{ $t("Range of dates") }}</label>
                  <vue-select 
                    :options="filter.dateRange.options" 
                    class="input cus-select payment-form_select no-uppercase-select"
                    v-model="filter.dateRange.selected"
                    @input="changeDateRange"
                  >
                  </vue-select> 
                </div>
              </div>
            </v-col>
          </v-row>
        <div class="second-row-filter">
          <v-row>
            <v-col lg="2" class="d-flex align-items-center p-5 space-nowrap">
              {{ $t("End Date") }} :
            </v-col>
            <v-col lg="2" class="d-flex align-items-center p-5">
              <Datepicker 
                v-model="filter.end_date" 
                :format="format"
                class="input tour-person__input cus-datepicker"
                :language="languages[currentLang]"
              ></Datepicker>
              <img src="/assets/img/calander.png" alt="" class="calendar-img">
            </v-col>
              <v-col lg="1">
            </v-col>
            <v-col lg="1" class="d-flex align-items-center p-5 space-nowrap">
              {{ $t("End hour") }} :
            </v-col>
            <v-col lg="2" class="d-flex align-items-center p-5">
              <vue-select 
                :options="filter.end_hours.options" 
                class="input cus-select time-select mx-2"
                v-model="filter.end_hours.selected"
              >
              </vue-select> : 
              <vue-select 
                :options="filter.end_mins.options" 
                class="input cus-select time-select mx-2"
                v-model="filter.end_mins.selected"
              >
              </vue-select>
            </v-col>
            <v-col>
              <div class="checkbox-time-limit"> 
                  <div class="d-flex align-items-center">
                    <v-checkbox v-model="filter.isTimeLimit" color="#0D3856" class="inline-checkbox"></v-checkbox>
                    <label class="space-nowrap">{{ $t("Without time limits") }}</label>
                  </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>

      </div>
    <div class="filter-part weight-700" style="padding-bottom: 50px;">
      <h5 class="weight-700 mb-5">{{ $t("General filtering") }}</h5>
      <div class="d-flex align-items-center flex-wrap">
        <v-row>
        <div class="filter-item d-flex align-items-center mb-5">
          <v-col>
          <label class="space-nowrap">
            {{ $t("Supplier ID") }} :
          </label>
          </v-col>
          <v-col></v-col>
          <v-col>
            <vue-select
                :options="suppliersOptionsList"
                :reduce="suppliersOption => suppliersOption.id"
                label="name"
                class="input cus-select mx-5 no-uppercase-select payment-form_select"
                v-model="filter.supplier_id"
            >
            </vue-select>
          </v-col>
        </div>
        <div class="filter-item mb-5 d-flex align-items-center">
            <v-col>
            <label class="m-0 space-nowrap">
              {{ $t("Number of transaction") }} :
            </label>
            </v-col>
          <v-col cols="12">
            <div class="d-flex align-items-center">
              <input class="input tour-person__input filter-width mx-5" type="text" v-model="filter.id" /> 
            </div>
          </v-col>
        </div>
        </v-row>
        <v-row>
        <div class="filter-item mb-5 d-flex align-items-center">
          <v-col>
            <label class="m-0 space-nowrap">
              {{ $t("Form of payment") }} :
            </label>
          </v-col>
          <v-col cols="10">
            <vue-select 
              :options="filter.payment_type.options" 
              class="input cus-select mx-5 no-uppercase-select "
              v-model="filter.payment_type.selected"
              style="width: 190px;"
            >
            </vue-select> 
          </v-col>
        </div>
        </v-row>
      </div>
    </div>
    </div>

    <button class="primary-btn search_btn" @click="filterReport(1)">{{ $t("Search") }}</button>

    <div class="filter_search">
        <img src="/assets/img/filter_icon.png" alt="filter_icon"  class="filter-img">
        <input class ="input" type="text" v-model="search"/>
    </div>

    <div class="table-content-part">
      <div class="d-flex justify-content-between">
        <div class="search-bar">
        </div>
      </div>
      <v-data-table
        :headers="headers"
        :items="searchFiltered"
        class="cus-table editable-table sales-table striped-table"
        :single-expand="false"
        :expanded.sync="expanded"
        item-key="id"
        :loading="loading"
        :options.sync="options"
        :server-items-length="reportData.total"
        :footer-props="{
          'items-per-page-options': itemsPerPageOptions
        }"
      >
        <template v-slot:[`item.expand`]="{ item }">
          <div v-if="item.supplier_type != suppliersTypes.prepaid">
            <v-btn small @click="expanded = [item]" v-if="!expanded.includes(item)">{{ $t('More') }}</v-btn>
            <v-btn small @click="expanded = []" v-if="expanded.includes(item)">{{ $t('Less') }}</v-btn>
          </div>
        </template>
        
        <template v-slot:[`item.product_name_he`]="{ item }" >
          <div v-if="item.supplier_id == suppliers.tourDeals" class="table_values">
            {{ item.dealTitle ? item.dealTitle : item.hotelName }}
          </div>
          <div v-else class="table_values">
            {{ item.product_name_he }}
          </div>
        </template>
        <template v-slot:[`item.supplier_name`]="{ item }" >
          <div class="table_values">
            {{ item.supplier_name }}
          </div>
        </template>

      <template v-slot:[`item.ip`]="{ item }" >
          <div class="table_values">
            {{ item.ip }}
          </div>
        </template>

      <template v-slot:[`item.business_name`]="{ item }" >
          <div class="table_values">
            {{ item.business_name }}
          </div>
        </template>
          <template v-slot:[`item.agent`]="{ item }" >
          <div class="table_values">
            {{ item.author_name  }}
          </div>
        </template> 
          <template v-slot:[`item.agent_id`]="{ item }" >
          <div class="table_values">
            {{ item.author_id  }}
          </div>
        </template> 


        <template v-slot:[`item.city`]="{ item }" >
          <div class="table_values">
            {{ item.client_address  }}
          </div>
        </template>

        <template v-slot:[`item.transaction_id`]="{ item }" >
          <div style="color: #0092cf; font-weight: bold;">
           {{ item.transaction_id }}
          </div>
        </template>


          <template v-slot:[`item.distributor_price`]="{ item }" >
          <div class="table_values">
           {{ item.distributor_price }}
          </div>
        </template>

          <template v-slot:[`item.business_price`]="{ item }" >
          <div class="table_values">
           {{ item.business_price }}
          </div>
        </template>

          <template v-slot:[`item.customer_id`]="{ item }" >
          <div class="table_values">
           {{ item.customer_id }}
          </div>
        </template>

        <template v-slot:[`item.status`]="{ item }" >
          <div  v-if="item.status == 5" class="table_values">
            {{ $t("Pending") }}
          </div>
          <div v-else-if="item.status == 3" class="table_values">
            {{ $t("Success") }}
          </div>
          <div v-else-if="item.status == 4" class="table_values">
            {{ $t("Failed") }}
          </div>
          <div class="ticket-item bg-1" v-else-if="item.status == 6">
            {{ $t("Cancelled") }}
          </div>
        </template>
        <template v-slot:[`item.date`]="{ item }" >
          <div class="table_values">
          {{getDateFromString(item.transaction_end_timestamp)}}
          </div>
        </template>
        <template v-slot:[`item.hour`]="{ item }" class="table_values">
          <div class="table_values">
          {{getTimeFromString(item.transaction_end_timestamp)}}
          </div>
        </template>
        <template v-slot:[`item.payment_cancel_status`]="{ item }" class="table_values">
          <div class="table_values" v-if="item.payment_cancel_status == 'YES'">
            {{ item.payment_cancel_status }}
          </div>
          <div class="table_values" v-else-if="item.payment_cancel_status == 'NO'">
            {{ item.payment_cancel_status }}
          </div>
        </template>
        <template v-slot:[`item.action`]="{ item }" class="table_values">
          <img 
            src="/assets/img/print.png" 
            alt="" 
            class="print-img hover-pointer"
            @click="rowPrint(item)">
        </template>
        
      </v-data-table>

    </div>
    <div class="action-part d-flex align-items-center">
      <div class="d-flex align-items-center" @click="printTableData">
        <img src="/assets/img/print.png" alt="" class="print-img hover-pointer mx-7">
        <label for="">{{ $t("For printing") }}</label>
      </div>
      <div class="d-flex align-items-center" @click="exportSalesReportHandler">
        <img src="/assets/img/excel.png" alt="" class="excel-img hover-pointer">
        <label for="">{{ $t("Export to Excel") }}</label>
      </div>
    </div>
    <loading :active.sync="showLoadingAnimation" :can-cancel="false" :is-full-page="true"></loading>
  </main>
</template>

<script>
import "bootstrap";
import "../../common/plugins/bootstrap-vue";
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { mapState, mapActions } from "vuex";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

import { saveAs } from 'file-saver';
export default {
  mixins: [validationMixin],
  name: "AdminSalesReport",
  components: {
    Loading
  },  
  computed: {
    ...mapState({
      loading: state => state.report.loading,
      exportInProgress: state => state.report.exportInProgress,
      reportData: state => {
        return state.report.salesReportData_receipts},
      printData: state => state.report.salesPrintData_receipts,
      supplier_items: state => state.supplier.supplier_items,
      contracts: state => state.report.salesDetails
    }),
    showLoadingAnimation() {
      return this.loading || this.exportInProgress;
    },
    currentLang: function() {
      let currentLang = localStorage.getItem("language");
      currentLang = currentLang ? currentLang.toLowerCase() : 'he';
      return currentLang;
    },
    suppliersOptionsList() {
      let suppliersOptionsList = [];

      if (this.supplier_items) {
          for (let supplierType in this.supplier_items) {
              let supplierTypeItems = this.supplier_items[supplierType];
              for (let supplerData of supplierTypeItems) {
                  suppliersOptionsList.push({id: supplerData.id, name: supplerData.name});
              }
          }
      }

      suppliersOptionsList.sort(function(a, b) {
        var nameA = a.name.toUpperCase(); // ignore upper and lowercase
        var nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      suppliersOptionsList.unshift({id: null, name: 'All'});

      return suppliersOptionsList;
    },
    statuses() {
      return {
        1: this.$t("Opened"),
        2: '2',
        3: this.$t("Success"),
        4: this.$t("Failed"),
        5: this.$t("Pending"),
        6: this.$t("Cancelled")
      };
    },
    fieldsToExport() {
      return [
        {
          name: this.$t("Deal No."),
          key: "transaction_id",
          field: "transaction_id"
        },
        {
          name: this.$t("Client No."),
          key: "customer_id",
          field: "customer_id"
        },
        {
          name: this.$t("city"),
          key: "city",
          field: "city"
        },
        {
          name: this.$t("agent"),
          key: "agent",
          field: "author_name"
        },
        {
          name: this.$t("Agent No."),
          key: "agent_id",
          field: "author_id"
        },
        {
          name: this.$t("Business price"),
          key: "business_price",
          field: "business_price"
        },
        {
          name: this.$t("Dist Price"),
          key: "distributor_price",
          field: "distributor_price"
        },
        {
          name: this.$t("Seller points"),
          key: "seller_points",
          field: "seller_points"
        },
        {
          name: this.$t("tags"),
          key: "tags",
          field: "seller_points"
        },
        {
          name: this.$t("IP"),
          key: "tags",
          field: "ip"
        },
        {
          name: this.$t("Business name"),
          key: "business_name",
          field: "business_name"
        },
        
        {
          name: this.$t("Mobile"),
          key: "phone_number",
          field: "phone_number"
        },
        {
          name: this.$t("Supplier ID"),
          key: "supplier_name",
          field: "supplier_name"
        },
        {
          name: this.$t("Product"),
          key: "product_name_" + this.currentLang,
          field: "product_name_" + this.currentLang
        },
        {
          name: this.$t("Date"),
          key: "transaction_date",
          field: "transaction_end_timestamp",
          dateFormat: "YYYY-MM-DD"
        },
        {
          name: this.$t("Hour"),
          key: "transaction_time",
          field: "transaction_end_timestamp",
          dateFormat: "HH:mm:ss"
        },
        {
          name: this.$t("Consumer price"),
          key: "price",
          field: "price"
        },
        {
          name: this.$t("Cost Price"),
          key: "business_price",
          field: "business_price"
        },
        {
          name: this.$t("Status"),
          key: "status",
          field: "status",
          options: this.statuses
        },
        {
          name: this.$t("Request for cancellation"),
          key: "payment_cancel_status",
          field: "payment_cancel_status"
        }
      ];
    },
    searchFiltered() {
      const search_term = ('' + this.search).toLowerCase();
      const fields_to_filter_by = ['author_id', 'author_name', '​​business_id', 'business_name', 'business_price', 'client_address', 'customer_id',
                                  'dealDestination', 'dealId', 'destinationName_ar', '​​destinationName_en', '​​destinationName_he', 'distributor_id',
                                  'distributor_name', 'distributor_price', 'phone_number', 'price', 'product_id', 'product_name_ar', 'product_name_en', 'ip',
                                  'product_name_he', 'status', 'supplier_id', '​​transaction_end_timestamp', 'transaction_start_timestamp', 'dealId', 'transaction_id']
      if(this.search === "") return this.reportData.data //if no search term was provided
      const res = this.reportData.data.filter( row => {
        for(const key of fields_to_filter_by){
          if(('' + row[key]).toLowerCase().includes(search_term)) return true
        }
        if(row['transaction_end_timestamp'] && row['transaction_end_timestamp'].substring(11, 19).includes(search_term)) return true;
  
        return false;   
      })
      return res
    }

  },
  data() {
    let startDate = new Date();
    let endDate = new Date();
    endDate.setDate(endDate.getDate() + 6);
    return {
      search: "",
      visible: false,
      headers: [
        {
          text: this.$t("Deal No."),
          align: "center",
          sortable: false,
          value: "transaction_id"
        },
        {
          text: this.$t("Business name"),
          align: "center",
          sortable: false,
          value: "business_name"
        },
        {
          text: this.$t("Client No."),
          align: "center",
          sortable: false,
          value: "customer_id"
        },
        {
          text: this.$t("city"),
          align: "center",
          sortable: false,
          value: "city"
        },
        {
          text: this.$t("agent"),
          align: "center",
          sortable: false,
          value: "author_name"
        },
        {
          text: this.$t("Agent No."),
          align: "center",
          sortable: false,
          value: "author_id"
        }, 
        {
          text: this.$t("Date"),
          align: "center",
          sortable: false,
          value: "date"
        },
        {
          text: this.$t("Hour"),
          align: "center",
          sortable: false,
          value: "hour"
        },
        {
          text: this.$t("Supplier ID"),
          align: "center",
          sortable: false,
          value: "supplier_name"
        },
        {
          text: this.$t("Product"),
          align: "center",
          sortable: false,
          value: "product_name_" + localStorage.getItem("language")
        },
        {
          text: this.$t("Consumer price"),
          align: "center",
          sortable: false,
          value: "consumer_price"
        },
        {
          text: this.$t("Business price"),
          align: "center",
          sortable: false,
          value: "business_price"
        },
        {
          text: this.$t("Dist Price"),
          align: "center",
          sortable: false,
          value: "distributor_price"
        },
        {
          text: this.$t("Cost Price"),
          align: "center",
          sortable: false,
          value: "business_price"
        },
        {
          text: this.$t("Status"),
          align: "center",
          sortable: false,
          value: "status"
        },
        {
          text: this.$t("Mobile"),
          align: "center",
          sortable: false,
          value: "phone_number"
        },
        {
          text: this.$t("Seller points"),
          align: "center",
          sortable: false,
          value: "seller_points"
        },
        {
          text: this.$t("tags"),
          align: "center",
          sortable: false,
          value: "tags"
        },
        {
          text: this.$t("IP"),
          align: "center",
          sortable: false,
          value: "ip"
        },
        
      ],
      sales_data: [
        {
          business_name: "הפיצוציה",
          mobile: "0543095861",
          supplier_id: "סלקום",
          product: "טוקמן 59 ללא הגבלה",
          date: "28-01-2019",
          hour: "23:11:52",
          consumer_price: "59",
          cost_price: "-54.21",
          status: "הושלם",
          payment_method: "מזומן",
          request_cancellation: "נוצל",
          transaction_number: "456456654",
          transaction_type: "חבילת נופש",
          customer_name: "עידו אילנית",
          to: "ברצלונה, ספרד",
          hotel: "הילטון דגכדגכ כדג",
          stars: "4",
          hosting: "ארוחת בוקר",
          passengers_number: "4"
        },
        {
          business_name: "הפיצוציה",
          mobile: "0543095860",
          supplier_id: "אמזון",
          product: "טוקמן 59 ללא הגבלה",
          date: "28-01-2019",
          hour: "23:11:52",
          consumer_price: "59",
          cost_price: "-54.21",
          status: "הושלם",
          payment_method: "מזומן",
          request_cancellation: "בטיפול",
          transaction_number: "456456654",
          transaction_type: "חבילת נופש",
          customer_name: "עידו אילנית",
          to: "ברצלונה, ספרד",
          hotel: "הילטון דגכדגכ כדג",
          stars: "4",
          hosting: "ארוחת בוקר",
          passengers_number: "4"
        }
      ],
      filter: {
        business_name:"",
        distributor_name: "",
        b_id: "",
        author_id: null,
        customer_id: "",
        isTimeLimit: false,
        supplier_id: null,
        id: "",
        phone: "",
        // dateRange: { startDate, endDate },
        payment_type: {
          selected: {label: "All", code: null},
          options: [
            {label: "All", code: null},
            {label: "CC", code: "cc"},
            {label: "Credit", code: "credit"},
            {label: "Cash", code: "cash"}
          ]
        },
        dateRange: {
          selected: {label: "None", code: 0},
          options: [
            {label: "Current month", code: 1}, 
            {label: "Last month", code: 2} ,
          ]
        },
        start_date: "",
        end_date: "",
        start_hours: {
          options: ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"],
          selected: "00"
        },
        start_mins: {
          options: ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48", "49", "50", "51", "52", "53", "54", "55", "56", "57", "58", "59"],
          selected: "00"
        },
        end_hours: {
          options: ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"],
          selected: "00"
        },
        end_mins: {
          options: ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48", "49", "50", "51", "52", "53", "54", "55", "56", "57", "58", "59"],
          selected: "00"
        },

      },
      
      format: "d MMMM yyyy",
      expanded: [],
      suppliers: {
        iecMatamId: 186,
        highway6Id: 187,
        tourDeals: 111,
        amazonId: 1000,
        playStationId: 1001,
        xboxOneId: 1002,
        steamId: 1006,
        googlePlayId: 1009,
        razerGoldId: 1010,
        netflixId: 1011,
        iTunesId: 1012,
        ravkav: 999
      },
      suppliersTypes: {
        prepaid: 1,
        bills: 2,
        manual_cards: 3,
        gift_cards: 4,
        tourism: 5
      },
      itemsPerPageOptions: [10, 15, 25, 50, 100],
      options: {
        itemsPerPage: 10,
        page: 1
      },
      filtersInUse: {}
    };
  },
  validations: {
    addForm: {
      group: {
        required
      },
      key: {
        required
      },
      en: {
        required
      },
      he: {
        required
      },
      ar: {
        required
      }
    }
  },
  methods: {
    ...mapActions("report", {
      filter_sales_report: "filter_sales_report",
      filter_sales_report_print: "filter_sales_report_print",
      export_sales_report: "export_sales_report",
      cancel_payment: "cancel_payment"
    }),
    ...mapActions('supplier', {
      getAllSupplierItems: 'getAll'
    }),

    /* -----validation---- */
    validateState(name) {
      const { $dirty, $error } = this.$v.addForm[name];
      return $dirty ? !$error : null;
    },
    getDateFromString(dateString) {
      if(dateString) {
        return dateString.substring(0, 10);
      }
    },
    getTimeFromString(dateString) {
      if(dateString) { 
        return dateString.substring(11, 19);
      } 
    },
    handleCancel(transactionId, supplierId, itemId, price) {
      //console.log(transactionId, supplierId, itemId)
      this.cancel_payment({transactionId, supplierId, itemId, price})
    },
    changeDateRange() {
      let today = new Date();
      let month = today.getMonth();
      let year = today.getFullYear();
      if (this.filter.dateRange.selected.code == 1) {
        this.filter.start_date = new Date(year, month, 1);
        this.filter.end_date = new Date(year, month + 1, 0);
        this.filter.start_hours.selected = "00"
        this.filter.start_mins.selected = "00"
        this.filter.end_hours.selected = "23"
        this.filter.end_mins.selected = "59"
      } else if (this.filter.dateRange.selected.code == 2) {
        if (month == 0) {
          year = year - 1;
          month = 12;
        }
        this.filter.start_date = new Date(year, month - 1, 1);
        this.filter.end_date = new Date(year, month, 0);
        this.filter.start_hours.selected = "00";
        this.filter.start_mins.selected = "00";
        this.filter.end_hours.selected = "23";
        this.filter.end_mins.selected = "59";
      }
    },

    prepareFilters() {
      let start_date = "";
      let end_date = "";
      if (this.filter.start_date != "") {
        let start_year = this.filter.start_date.getFullYear();
        let start_month = (this.filter.start_date.getMonth() + 1).toString().padStart(2, '0');
        let start_day = this.filter.start_date.getDate().toString().padStart(2, '0');
        start_date = start_year + "-" + start_month + "-" + start_day + "T" + this.filter.start_hours.selected + ":" + this.filter.start_mins.selected + ":00";
      }

      if (this.filter.end_date != "") {
        let end_year = this.filter.end_date.getFullYear();
        let end_month = (this.filter.end_date.getMonth() + 1).toString().padStart(2, '0');
        let end_day = this.filter.end_date.getDate().toString().padStart(2, '0');
        end_date = end_year + "-" + end_month + "-" + end_day + "T" + this.filter.end_hours.selected + ":" + this.filter.end_mins.selected + ":00";
      }
      const filters = {
        start: start_date,
        end: end_date,
        payment_type: this.filter.payment_type.selected.code,
        id: this.filter.id,
        supplier_id: this.filter.supplier_id,
        phone: this.filter.phone,
        business_name: this.filter.business_name,
        customer_id: this.filter.customer_id,
        distributor_name: this.filter.distributor_name,
        author_id: this.filter.author_id,
        business_id_filter: this.filter.business_id_filter,
      }

      return filters;
    },
    filterReport(page) {
      page = +page || +this.options.page;
      this.options.page = page;
      let itemsPerPage = +this.options.itemsPerPage;
      let skip = (page - 1) * itemsPerPage;
      if (page === 1) {
        this.filtersInUse = this.prepareFilters()
      }
      const filters = this.filtersInUse;
      this.filter_sales_report({limit: itemsPerPage, skip: skip, filters: filters});
    },
     
    /* print one row */ 
    rowPrint(item) {
      item.date = this.getDateFromString(item.transaction_end_timestamp);
      item.hour = this.getTimeFromString(item.transaction_end_timestamp);

      let defaultProperties = [
        { field: 'payment_cancel_status', displayName: this.$t("Request for cancellation") },
        { field: 'status', displayName: this.$t("Status") },
        { field: 'business_price', displayName: this.$t("Cost Price") },
        { field: 'price', displayName: this.$t("Consumer price") },
        { field: 'hour', displayName: this.$t("Hour") },
        { field: 'date', displayName: this.$t("Date") },
        { field: 'product_name_' + this.currentLang, displayName: this.$t("Product") },
        { field: 'supplier_name', displayName: this.$t("Supplier ID") },
        { field: 'phone_number', displayName: this.$t("Mobile") },
        { field: 'business_name', displayName: this.$t("Business name") },
        { field: 'transaction_id', displayName: this.$t("Deal No.") },
        { field: 'distributor_price', displayName: this.$t("Dist Price") },
        { field: 'business_price', displayName: this.$t("Business price") },
        { field: 'customer_id', displayName: this.$t("Client No.") },
        { field: 'distributor_name', displayName: this.$t("Distributor") },
        { field: 'author_id', displayName: this.$t("Agent No.") },
        { field: '​​business_id', displayName: this.$t("Business ID") },
        { field: 'ip', displayName: this.$t("IP") },
        
      ]

      let addtionalProperties = [];
      if (item.supplier_type == this.suppliersTypes.tourism) {
        if (!item.external_code) {
          item.external_code = '-';
        }
        if (!item.dealType) {
          item.dealType = '-';
        }
        if (Array.isArray(item.persons)) {
          item.updated_customer_name = [].concat.apply([], item.persons).map(person => person.is_customer == 1 ? person.firstName + ' ' + person.lastName : null).filter(customer => customer != null).join(' | ')
        } else {
          item.updated_customer_name = item.customer_name;
        }
        if (!item.hotelName) {
          item.hotelName = '-';
        }
        if (!item.hotelRating) {
          item.hotelRating = '-';
        }
        if (item.farebasis) {
          item.farebasis = item.farebasis.join(' | ');
        } else {
          item.farebasis = '-';
        }
        if (item.paxcomplect) {
          item.paxcomplect = item.paxcomplect.join('').length;
        } else {
          item.paxcomplect = '-';
        }

        addtionalProperties = [
          { field: 'id', displayName: this.$t("Number of transaction") },
          { field: 'external_code', displayName: this.$t("External Order ID") },
          { field: 'dealType', displayName: this.$t("Transaction type") },
          { field: 'updated_customer_name', displayName: this.$t("Customer name") },
          { field: 'destinationName_' + this.currentLang, displayName: this.$t("To") },
          { field: 'hotelName', displayName: this.$t("Hotel") },
          { field: 'hotelRating', displayName: this.$t("Stars") },
          { field: 'farebasis', displayName: this.$t("Hosting") },
          { field: 'paxcomplect', displayName: this.$t("Number of passengers") },
          
        ]
      } else if (item.supplier_type == this.suppliersTypes.gift_cards) {
        if (!item.external_transaction_id) {
          item.external_transaction_id = '-';
        }
        if (!item.external_code) {
          item.external_code = '-';
        }
        addtionalProperties = [
          { field: 'id', displayName: this.$t("Number of transaction") },
          { field: 'external_transaction_id', displayName: this.$t("External Order ID") },
          { field: 'external_code', displayName: this.$t("Gift Card Code") }
        ]
      } else if (item.supplier_id == this.suppliers.iecMatamId) {
        addtionalProperties = [
          { field: 'contract_number', displayName: this.$t("Contract number") }
        ]
      } else if (item.supplier_id == this.suppliers.highway6Id) {
        addtionalProperties = [
          { field: 'customer_id', displayName: this.$t("ID number") },
          { field: 'bill_number', displayName: this.$t("Bill number") }
        ]
      }

      let lineProperties = [ ...defaultProperties, ...addtionalProperties ];
      const printData = [];
      printData.push(item);
      printJS({
        printable: printData, 
        properties: lineProperties,
        type: 'json'
      })
    },
    /* print table */ 
    async printTableData() {
      const filters = this.filtersInUse;
      const totalRowsNumber = this.reportData.total;
        this.filter_sales_report_print({filters: filters, total: totalRowsNumber}).then(
          result => {
            printJS({
              printable: result, 
              properties: [
                { field: 'payment_cancel_status', displayName: this.$t("Request for cancellation") },
                { field: 'status', displayName: this.$t("Status") },
                { field: 'business_price', displayName: this.$t("Cost Price") },
                { field: 'price', displayName: this.$t("Consumer price") },
                { field: 'hour', displayName: this.$t("Hour") },
                { field: 'date', displayName: this.$t("Date") },
                { field: 'product_name_' + this.currentLang, displayName: this.$t("Product") },
                { field: 'supplier_name', displayName: this.$t("Supplier ID") },
                { field: 'phone_number', displayName: this.$t("Mobile") },
                { field: 'business_name', displayName: this.$t("Business name") },
                { field: 'transaction_id', displayName: this.$t("Deal No.") },
                { field: 'distributor_price', displayName: this.$t("Dist Price") },
                { field: 'business_price', displayName: this.$t("Business price") },
                { field: 'customer_id', displayName: this.$t("Client No.") },
                { field: 'distributor_name', displayName: this.$t("Distributor") },
                { field: 'author_id', displayName: this.$t("Agent No.")},
                { field: '​​business_id', displayName: this.$t("Business ID") },
                { field: 'ip', displayName: this.$t("IP") },
              ],
              type: 'json',
              gridHeaderStyle: this.currentLang == 'en' ? 'direction: ltr; border: 1px solid #3971A5;' : 'direction: rtl; border: 1px solid #3971A5;',
              gridStyle: this.currentLang == 'en' ? 'direction: ltr; border: 1px solid #3971A5;' : 'direction: rtl; border: 1px solid #3971A5;'
            })
          },
          error => {
          }
        );
    },

    exportSalesReportHandler(event) {
      if (!this.reportData || !this.reportData.total) {
        return false;
      }
      const filters = this.filtersInUse;
      const fields = this.fieldsToExport;
      this.export_sales_report({format: 'excel', fields: fields, filters: filters}).then(
        result => {
          saveAs(result, 'sales_report_' + moment().format('YYYY-MM-DD') + '.xlsx');
        },
        error => {
        }
      );
    }
  
  },
  watch: {
      options: {
          handler () {
              this.filterReport();
          },
          deep: true,
      },
  },
  mounted() {
    this.filter.start_date = new Date();
  },
  created() {
    this.getAllSupplierItems();
  }
};
</script>

<style lang="scss">
.time-select{
  width: 70px;
  .vs__selected {
    height: 100%;
    top: 0;
  }
  .vs__dropdown-menu {
    min-width: 70px;
    max-width: 70px;
  }
}
.payment-form_select {
  min-width: 170px;
}
.cus-select {
  ul {
    padding-left: 0;
  }
} 
.inline-checkbox .v-input__control {
  height: 100%;
  padding-bottom: 0px;
}
.sales2-range-date {
  display: flex;
  align-items: center;
  label {
    margin:0 20px 0 0;
  }
  .range-datepicker {
    width: 260px;
    .reportrange-text {
      border-radius: 8px;
      padding: 0 10px;
      height: 30px;
      span {
        font-size: 18px;
        color: #002036;
      }
    }
  }
}
.text_center {
  text-align: center;
  float: right;
  padding-left: 100px;
}
.position {
  display: contents;
}

</style>
<style scoped lang="scss">
.translation {
  height: 100%;
  color: #002036;
}

.lang-title {
  font-size: 30px;
  margin-top: 10px;
  text-align: center;
}

.filters-flex-container {
  display: flex;
  flex: 1;
  justify-content: center;
  flex-basis: 100%;
  flex-wrap:nowrap;
  height: 200px;
}

.filter-part {
  border: 1px solid #707070;
   margin: 20px 50px 0 50px;
   padding: 10px 15px;
   width:50%;
  .input {
    height: 30px;
    padding: 0 10px;
    border-radius: 8px;
  }

  .calendar-img {
    width: 25px;
    position: absolute;
    right: 12px;
    left: unset;
  }
  .v-input__control {
    height: 100%;
  }
  [class*="col-"] {
    padding: 0 !important;
  }
  .row {
    margin: 0;
  }
  .time-part {
    margin-left: 50px;
    margin-right: 0;
    min-width: 300px;
    .vue-select {
      padding: 0 4px;
    }
  }
  .range-part {
    margin-right: 50px;
    position: relative;
    z-index: 1;
    transform: translateY(-15px);
  }
  .filter-search_icon {
    position: absolute;
    left: 14px;
    width: 20px;
    top: 5px;
  }
  .filter-item {
    margin-left: 0;
    margin-right: 20px;
    
  }
}

.total-value {
  font-weight: 600;
  color: #707070;
  font-size: 22px;
  span {
    font-size: 18px;
    color: #002036;
  }
}
.sales-table {
  .ticket-item {
    border: 1px solid #707070;
    color: #fff;
    border-radius: 5px;
    width: 70px;
    margin: 0 auto;
    text-align: center;
    &.expand-ticket-item {
      width: auto;
      padding: 0 5px;
    }
  }
  .print-img {
    width: 40px;
  }
}
.label-align {
  justify-content: flex-end;
}
.icon-div {
  background-color: #002036;
  width: 25px;
  min-width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.search_btn {
  margin: 0 auto;
  margin-top: 20px;
}
.expand-part {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 0 10px;
  div p:before {
    content: ' ';
    display: inline-block;
  }
  p {
    margin: 10px 0;
  }
  .full-detail_btn {
    background-color: #fff;
    color: #000;
    font-weight: 700;
    border: 1px solid #707070;
    border-radius: 5px;
    padding: 7px 20px;
  }
}
.expand-part-red {
  align-items: center;
  justify-content: space-around;
  padding: 0 10px;
  color: red;
  p {
    margin: 10px 0;
  }
  .full-detail_btn {
    background-color: #fff;
    color: #000;
    font-weight: 700;
    border: 1px solid #707070;
    border-radius: 5px;
    padding: 7px 20px;
    margin: 10px 0;
  }
}
.action-part {
  margin: 20px 70px;
  .print-img {
    width: 50px;
  }
  .excel-img {
    width: 60px;
    margin-left: 50px;
    margin-right: 15px;
  }
  label {
    font-weight: 700;
    margin: 0;
  }
  >div {
    cursor: pointer;
    label {
      cursor: pointer;
    }
  }
}
.rtl-type {
  label-align {
    justify-content: flex-start;
  }
  .excel-img {
    margin-right: 50px;
    margin-left: 15px;
  }
  .filter-part {
    .filter-item {
      margin-left: 20px;
      margin-right: 0;
    }
    .time-part {
      margin-right: 50px;
      margin-left: 0;
    }
    .calendar-img {
      left: 12px;
      right: unset;
    }
  }
  .sales2-range-date label {
    margin: 0 0 0 20px;
  }
  .time-select .vs__actions {
    margin-right: -10px;
  }
}
@media screen and (max-width: 1548px){
  .filter-part {
    .range-part {
      margin-right: 0;
      margin-left: 0;
    }
  } 
}
@media screen and (max-width: 768px){
  .filter-part {
    .time-part {
      margin-right: 0;
      margin-left: 0;
    }
  } 
}
@media screen and (max-width: 480px){
  .filter-part {
    margin: 20px 20px 0;
    .filter-item {
      width: 100%;
      flex-wrap: wrap;
      margin: 10px 0 0 !important;
      label {
        width: 100%;
      }
      .input {
        width: 100%;
        margin: 0 !important;
      }

      .icon-div {
        margin-left: 10px;
        margin-right: 0;
      }
    }
  }
  .search_btn {
    max-width: 80%;
  }
  .rtl-type {
    .icon-div {
      margin-right: 10px;
      margin-left: 0;
    }
  }
}
@media screen and (min-width: 992px){
  .filter-width {
    max-width: 170px;
  }
}
@media screen and (max-width: 992px){
  .label-align {
    justify-content: flex-start;
    padding-bottom: 0 !important;
  }
  .rtl-type .label-align {
    justify-content: flex-end;
    padding-bottom: 0 !important;
  }
}

.table_values {
  color: #002036;
}

.header_input_container{
  border: 1px solid #707070;
   margin: 20px 50px 0 50px;
   padding: 10px 15px 30px;
  .inputs_container{
    height: 30px;
    padding-top: 10px;
    border-radius: 8px;
  }
  .input_titles{
    padding-right: 7px;
  }
}

.filter_search{
  display: inline;
  padding-right: 50px;

  .input{
    outline:0;
    border-width: 0 0 2px;
    width: 200px;
    background-color: transparent;
  }
  input:focus {
    border-color: #0D3856;
    
  }
}

.checkbox-time-limit{
  margin-right: 35px;
  z-index: 1;
  position: relative;
}
.second-row-filter{
  width: 100%;
  transform: translateY(-20px);
  
}



</style>